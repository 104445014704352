import React from 'react';
import Contents from './contents';

const About: React.FC = () => {
    return (
        <div className="home">
            about
            <Contents />
        </div>
    )
};

export default About;
